import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useUserContext } from '~context/UserContext/UserSessionContext';
import MessageBox from '~components/forms/MessageBox';
import Link from '~components/Link';
import { useSupportInNeedRegistration } from '~hooks/useAuth';
import InputField from './components/InputField';

const useStyles = makeStyles(theme => ({
  requiredIndicatorColor: {
    color: '#d93025'
  },
  fullWidthForm: {
    width: '100%',
    marginBottom: '100px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '80px'
    }
  },
  formTitle: {
    fontWeight: '500',
    fontSize: '32px',
    lineHeight: '34px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  unemployedDisclaimerText: {
    paddingTop: '6px',
    paddingLeft: '8px',
    marginBottom: '20px',
    fontSize: '18px',
    lineHeight: '28px'
  },
  formButton: {
    textTransform: 'none',
    marginBottom: '18px',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '34px',
    padding: '11px 22px'
  },
  disclaimerText: {
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '13px',
    color: '#000000'
  }
}));

const SupportInNeedForm = () => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const { session } = useUserContext();
  const { user, isLoggedIn } = session;

  const [state, registerSupportInNeed] = useSupportInNeedRegistration();

  const { register, handleSubmit } = useForm();

  const { isLoading, isError, data: registrationData, error } = state;

  const { meta_first_name, meta_last_name, username } = user;

  const handleCheckboxClick = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }
    if (registrationData) {
      navigate('/thank-you');
    }
  }, [isLoading, isError, registrationData]);

  const onSubmit = formData => {
    registerSupportInNeed({
      ...formData,
      first_name: formData.first_name || meta_first_name,
      last_name: formData.last_name || meta_last_name,
      username: formData.username || username
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.fullWidthForm}>
      <Box bgcolor="#fff" px={5} py={4} mb={2} borderRadius="5px" width="100%">
        <Typography variant="subtitle1" align="center" className={classes.formTitle} gutterBottom>
          Learn with us
        </Typography>
        <InputField
          label="First Name"
          name="first_name"
          autoComplete="given-name"
          inputRef={register}
          value={meta_first_name || undefined}
          required
          disabled={!!meta_first_name}
        />

        <InputField
          label="Last Name"
          name="last_name"
          autoComplete="family-name"
          inputRef={register}
          value={meta_last_name || undefined}
          required
          disabled={!!meta_last_name}
        />

        <InputField
          name="username"
          type="email"
          autoComplete="email"
          label="Email"
          value={isLoggedIn ? username : undefined}
          inputRef={register}
          required
          disabled={isLoggedIn}
        />
        <InputField
          name="referral_source"
          label="How did you hear about this initiative?"
          autoComplete="organization"
          inputRef={register}
          disabled={isLoading}
          required
        />
        <div className={classes.row}>
          <Checkbox
            name="is_currently_in_israel"
            checked={checked}
            onChange={handleCheckboxClick}
            inputRef={register}
            color="primary"
            style={{ marginLeft: '-9px' }}
          />

          <Typography variant="subtitle1" className={classes.unemployedDisclaimerText}>
            I am currently in Israel. I understand that my eligibility for this offer is conditioned
            on the accuracy of this information.
          </Typography>
        </div>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={isLoading || !checked}
          className={classes.formButton}
          fullWidth
        >
          Sign up
        </Button>
        <Typography variant="subtitle1" align="center" className={classes.disclaimerText}>
          By continuing, you agree to Aleph Beta’s <Link to="/terms">Terms & Conditions</Link> and
          <Link to="/privacy"> Privacy Notice</Link>
        </Typography>
      </Box>

      {isError && <MessageBox text={error} isError />}
    </form>
  );
};

export default SupportInNeedForm;
