import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import ScrollImage from './images/Scroll.png';

import SupportInNeedForm from '../SupportInNeedForm';

const useStyles = makeStyles({
  media: {
    display: 'flex',
    maxWidth: '100%',
    margin: '0 auto',
    marginBottom: '10px'
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: '64px',
    marginBottom: '38px'
  },
  gridSpacing: {
    marginTop: '80px'
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: '34px',
    marginBottom: '40px'
  }
});
const SupportInNeedHero = () => {
  const classes = useStyles();
  return (
    <Container background="#A2A0FF">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classes.gridSpacing}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h2" align="center" className={classes.heading}>
            Free Aleph Beta Accounts for All Those Currently In Israel
          </Typography>
          <Typography variant="subtitle1" align="center" className={classes.subtitle}>
            To help those affected by the current situation in Israel, we’re offering Aleph Beta
            Premium Membership for free to all those currently in Israel. Discover the wisdom and
            guidance hidden in the Torah text, and may it bring you inspiration, strength and
            comfort through this time. Your account will be free through May 1st 2024, after which
            you will be able to choose whether you want to continue on with Aleph Beta as a paid
            member.
          </Typography>
          <img src={ScrollImage} alt="Scroll" className={classes.media} />
        </Grid>
        <Grid item xs={12} md={5}>
          <SupportInNeedForm />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SupportInNeedHero;
