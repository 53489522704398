import React from 'react';

import Page from '~layout/Page';

import { SupportInNeedHero, SupportInNeedFaq } from '~modules/support-in-need';

const SupportInNeedPage = () => (
  <Page>
    <SupportInNeedHero />

    <SupportInNeedFaq />
  </Page>
);

export default SupportInNeedPage;
